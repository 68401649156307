import React from "react";
import "./grid.scss";

function ImageGrid({ editionData, onViewMore }) {
  return (
    <div className="image-container">
      {Object.keys(editionData).map((editionId) => {
        const edition = editionData[editionId];
        return (
          <div key={editionId} className="image-wrapper">
            <img src={edition.images[0]?.small} alt={edition.titles} />
            <div className="image-text" onClick={() => onViewMore(editionId)}>
              {edition.title} {/* Exibe o título ou um fallback */}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ImageGrid;
